import { Component, OnInit, Self } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { CartService } from '../_services/cart.service';
import { Router } from '@angular/router';
import { Order } from '../_models/order';
import { takeUntil } from 'rxjs/operators';
import { NgOnDestroy } from '../_services/ng-on-destroy.service';

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  providers: [NgOnDestroy]
})
export class LogoutComponent implements OnInit {
  cartRegs: number = 0;
  totalPayment: number = 0;
  viewCart: boolean = true;
  constructor(@Self() private destroy: NgOnDestroy, private router: Router, private authService: AuthService, private cartService: CartService) {  }

  ngOnInit() {
    if (!localStorage.getItem("user")) this.viewCart = false;
    this.load();
    this.cartService.notifyObservable$.subscribe(res => {
      if (res.refresh) {
        this.load();
      }
    })
  }

  load() {
    if (this.viewCart) {
      this.cartService.getRegsFromCart().pipe(takeUntil(this.destroy)).subscribe((data: Order) => {
        this.cartRegs = data.regs.length;
        this.totalPayment = 0;
        for (let reg of data.regs) {
          if (reg.status.name == "Ожидается оплата") {
            this.totalPayment++;
          }
        }
      });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate([location.pathname]);
  }
  
edit() {
    this.router.navigate(['/settings']);
  }
  paymenthistory() {
    this.router.navigate(['/paymenthistory']);
  }
  statistics() {
    this.router.navigate(['/statistics']);
  }
  authTittle(): string {
    return this.authService.tittle;
  }

  authTittleName(): string {
    return this.authService.titleName;
  }

}
