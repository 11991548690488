<div class="cookie__item">
  <div class="d-flex">
    <p>
      {{'COOKIE.FIRST' | translate}} <a target="_blank" href="https://rikc.by/ru/cookies _rikc.by.pdf">
        {{'COOKIE.SECOND' | translate}}</a>.
    </p>

    <div>
      <div class="cookie__item--buttons">
        <button (click)="accept()" class="btn btn-success"> {{'COOKIE.YES' | translate}}</button>
        <button class="btn btn-light"> {{'COOKIE.NO' | translate}}</button>
      </div>
    </div>
  </div>