<ngx-spinner type="ball-scale-multiple"></ngx-spinner><div class="container__element">
<app-header></app-header>
<div class="logout__panel">
    <logout *ngIf="auth()"></logout>
</div>
<div class="container__element--page">
<div class="container-fluid">
<router-outlet></router-outlet>
</div>
</div>
<div class="container__element--footer">
<app-footer></app-footer>
</div>
</div>