import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-cookie-request-dialog',
  templateUrl: './cookie-request-dialog.component.html',
  styleUrl: './cookie-request-dialog.component.css'
})
export class CookieRequestDialogComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<CookieRequestDialogComponent>) { }

  accept() {
    localStorage.setItem("ca", "true");
    this.bottomSheetRef.dismiss();
  }
}
