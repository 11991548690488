import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID, APP_ID } from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LogoutComponent } from './logout/logout.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSpinnerModule } from "ngx-spinner";
import { CookieRequestDialogComponent } from './cookie-request-dialog/cookie-request-dialog.component';
import { FileSaverModule } from 'ngx-filesaver';
import { MetrikaModule } from "ng-yandex-metrika"
registerLocaleData(localeRu, 'ru')


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LogoutComponent,
    FooterComponent,
    HeaderComponent,
    CookieRequestDialogComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
    NgbCollapseModule,
    NgbDropdownModule,
    MatDialogModule,
    //NgbModule,
    CommonModule,
    MetrikaModule.forRoot([
      { id: 57171532, webvisor: true, accurateTrackBounce: true, clickmap: true, trackLinks: true },
    ]),
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FileSaverModule
  ],
  providers: [
    { provide: APP_ID, useValue: 'ng-cli-universal' },
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
