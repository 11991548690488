<div class="logout__items">

  <div *ngIf="viewCart" class="logout__items--cart">
    <a routerLink="/cart">
      <img alt="" src="assets/img/cart.svg">
      <span class="items__cart--counter">{{ totalPayment }}</span>
    </a>
  </div>

  <!-- <div class="logout__items--notify"> -->
   <!-- <div ngbDropdown display="dynamic" placement="bottom-right"> -->
        <!-- <a class="nav-link" ngbDropdownToggle id="navbarDropdown2" role="button"> -->
		<!-- <img alt="" src="assets/img/notify.svg"> -->
        <!-- <span class="items__notify--counter">0</span></a> -->
        <!-- <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu"> -->
		<!-- <div class="dropdown-menu--notify"> -->
		<!-- <div class="menu__notify--header"> -->
		<!-- <span>Уведомления</span> -->
		<!-- <img alt="" src="assets/img/notify-readall.svg">		 -->
		<!-- </div> -->
		<!-- <div class="dropdown-divider"></div> -->
		<!-- <div class="menu__notify--body"> -->
		<!-- <ul> -->
         <!-- <li>Результат РТ с #Регистрации <b>0370855</b></li> -->
		 <!-- <li>Результат РТ с #Регистрации <b>0369861</b></li> -->
		 <!-- </ul> -->
		 <!-- </div> -->
		 <!-- <div class="menu__notify--footer"> -->
		 <!-- Будь в курсе всех событий! -->
		 <!-- </div> -->
        <!-- </div> -->
		<!-- </div> -->
 <!-- </div> -->
  <!-- </div> -->

  <div class="logout__items--user"> 
 <div ngbDropdown display="dynamic" placement="bottom-right">
        <a class="nav-link" ngbDropdownToggle id="navbarDropdown3" role="button">{{ authTittle() }}</a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
          <div class="dropdown-menu--user">
            <span>{{ authTittleName() }}</span>
            <div class="dropdown-divider"></div>
            <a *ngIf="viewCart" ngbDropdownItem href="/documents"><i class="fa fa-file-text-o" aria-hidden="true"></i>{{'LOGOUT.DOCUMENT'| translate}}</a>
            <a *ngIf="viewCart" ngbDropdownItem (click)="edit()"><i class="fa fa-cogs" aria-hidden="true"></i>{{ 'HOME.EDIT' | translate }}</a>
            <!-- <a *ngIf="viewCart" ngbDropdownItem (click)="paymenthistory()"><i class="fa fa-credit-card" aria-hidden="true"></i>{{ 'HOME.HISTORY' | translate }}</a> -->
            <!-- <a *ngIf="viewCart" ngbDropdownItem (click)="statistics()"><i class="fa fa-bar-chart" aria-hidden="true"></i>{{ 'HOME.STATISTICS' | translate }}</a> -->
            <button class="btn btn-danger" (click)="logout()"><i class="fa fa-sign-out"></i>{{ 'LOGOUT.TITLE' | translate }}</button>
          </div>
		</div>
 </div>
  </div>

</div>

