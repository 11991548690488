/* src/app/header/header.component.css */
header {
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
header a {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.h-pane {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px !important;
}
.h-pane__logo img {
  width: 90px;
}
.h-pane__nam {
  max-width: 280px;
  line-height: 1rem;
  letter-spacing: .025rem;
  font-size: 14px;
  position: relative;
  padding: 12px 40px;
  text-shadow: 2px 2px rgba(100, 100, 100, 0.1);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
.h-pane__nam:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background:
    linear-gradient(
      to bottom,
      rgba(75, 124, 148, 0.7) 0%,
      rgba(75, 124, 148, 0.7) 100%);
  transform: skew(150deg);
  z-index: -1;
}
.h-pane__logo {
  text-align: left;
  z-index: 2;
}
.h-pane__ico-item {
  transform: translate3d(0, 0, 0);
  transition: transform .2s;
}
.h-pane__ico-item:hover {
  transform: translate3d(0, 1px, 0);
}
.h-pane__ico-img {
  background-color: rgba(75, 124, 148, 0.79);
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  transition: all .4s ease;
}
.h-pane__ico-img {
  width: 25px;
  height: 20px;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: center;
}
.h-pane__ico-item:nth-child(1) .h-pane__ico-img {
  -webkit-mask-image: url(/assets/img/icon-loc.svg);
  mask-image: url(/assets/img/icon-loc.svg);
}
.h-pane__ico-item:nth-child(2) .h-pane__ico-img {
  -webkit-mask-image: url(/assets/img/icon-card.svg);
  mask-image: url(/assets/img/icon-card.svg);
  width: 28px;
  height: 25px;
}
.h-pane__ico-item:nth-child(3) .h-pane__ico-img {
  -webkit-mask-image: url(/assets/img/icon-user.svg);
  mask-image: url(/assets/img/icon-user.svg);
}
.h-pane__ico-item:nth-child(4) .h-pane__ico-img {
  -webkit-mask-image: url(/assets/img/icon-feedback.svg);
  mask-image: url(/assets/img/icon-feedback.svg);
  width: 28px;
}
.h-pane__ico-item img {
  opacity: .8;
}
.h-pane__ico-item img:hover {
  opacity: 1;
}
.bs-anim {
  transition: all .2s ease-out;
}
.h-pane__ico-item a {
  font-size: 12px;
}
.h-pane__ico-text {
  padding-left: 6px;
}
.h-pane__ico-item a {
  display: flex;
  align-items: center;
  text-align: left;
}
.h-pane__ico-item a {
  padding: 5px;
  font-size: 11px;
  line-height: 1;
}
.h-pane__ico {
  flex-basis: 275px;
}
.h-pane__ico {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 140px;
  z-index: 2;
}
.h-pane__support {
  font-size: 14px;
  margin-right: 2rem;
}
.h-pane__support-number {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
}
.h-pane__support-number i {
  font-size: 1.2rem;
  margin-right: .4rem;
}
.h-pane__support ul,
.h-pane__support .nav-link {
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.dropdown-menu.show li {
  padding: .1rem .6rem;
  display: flex;
  align-items: center;
  font-size: .9rem;
}
.h-pane__support .dropdown-menu:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #e7f0f7;
  position: absolute;
  top: -6px;
  right: 10px;
}
.h-pane__link .dropdown-menu {
  border: 1px solid #e7f0f7;
}
.dropdown-toggle::after {
  border: none !important;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f107" !important;
  vertical-align: 0;
  position: relative;
  right: -5px;
}
.h-pane__support ul li i {
  margin-right: .5rem;
  color: #4d636d;
}
.h-pane__support ul li a {
  padding: 0;
}
.h-pane__support ul li .fa-mobile {
  margin-right: .8rem;
  font-size: 1.2rem;
  position: relative;
  left: 2px;
}
.h-pane__support ul li:hover,
.h-pane__support ul li:hover a,
.h-pane__support ul li:hover i {
  color: #fff;
}
@media (min-width: 992px) {
  .h-pane__link {
    display: flex;
    align-items: center;
    justify-content: inherit;
    flex-grow: 1;
    padding: 0 0 0 40px;
  }
  .h-pane__logo {
    min-width: 150px;
  }
  .h-pane__translate {
    order: 1;
  }
}
@media (max-width: 992px) {
  .h-pane__link.show {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .h-pane__ico {
    flex-wrap: wrap;
    width: 100%;
    flex-basis: 100%;
    padding: 0 5px;
  }
  .h-pane__support .nav-link {
    display: none;
  }
  .navbar img {
    width: 70px;
  }
}
@media (max-width: 1190px) {
  .h-pane__support {
    display: none;
  }
}
@media (max-width: 500px) {
  .h-pane__nam {
    max-width: 180px;
    line-height: .9rem;
    font-size: 12px;
    padding: 6px;
  }
  header {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar img {
    width: 50px;
  }
  .h-pane__translate button {
    padding: 2px 4px;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .navbar-toggler {
    display: none;
  }
}
/*# sourceMappingURL=header.component.css.map */
