/* src/app/app.component.css */
.phone__logout li {
  list-style: none;
  cursor: pointer;
}
.phone__logout li ul {
  padding: 0 6px;
}
.phone__logout .fa {
  font-size: 18px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.phone__logout li ul li {
  margin: 6px 0;
}
.phone__logout li ul li:hover {
  background: none;
  color: inherit;
}
.phone__logout li ul li a:hover {
  color: inherit;
}
.phone__logout .fa-mobile {
  margin-left: 4px;
}
.phone__logout ul a {
  display: inline-block;
  padding: 0;
}
.dropdown-toggle::after {
  top: 2px;
  position: relative;
}
.dropdown-menu:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #dcdada;
  position: absolute;
  top: -6px;
  left: 10px;
}
@media (max-width: 767px) {
  .phone__logout {
    display: none;
  }
  .body-content {
    padding-top: 50px;
  }
  .phone-logout .name-logout {
    white-space: nowrap;
    font-size: 14px;
  }
  .phone-logout .phone {
    font-size: 14px;
  }
  .phone-logout .fa {
    font-size: 20px;
  }
}
/*# sourceMappingURL=app.component.css.map */
