import { Component, OnInit } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatBottomSheet } from "@angular/material/bottom-sheet"
import { Location } from '@angular/common';
import { CookieRequestDialogComponent } from "./cookie-request-dialog/cookie-request-dialog.component";
import { filter } from "rxjs";
import { Metrika } from "ng-yandex-metrika";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AuthService]
})
export class AppComponent implements OnInit {
  logoutTimeout: NodeJS.Timeout;

  constructor(private authService: AuthService, private translate: TranslateService, private router: Router, private bottomSheet: MatBottomSheet, location: Location, private metrika: Metrika,) {
    translate.addLangs(['ru', 'by']);
    translate.setDefaultLang('ru');
    translate.use(localStorage.getItem('lang') || 'ru');
    let bodyElem: HTMLBodyElement = document.getElementsByTagName("body")[0];
    bodyElem.addEventListener("click", this.onActions);
    bodyElem.addEventListener("touchstart", this.onActions);
    bodyElem.addEventListener("keypress", this.onActions);
    this.onActions();
    let prevPath = location.path();
    this.router
      .events
      .pipe(filter(event => (event instanceof NavigationEnd)))
      .subscribe(() => {
        const newPath = location.path();
        this.metrika.hit(newPath, {
          referer: prevPath,
          // callback: () => { console.log('hit end'); }
        });
        prevPath = newPath;
      });
  }

  ngOnInit(): void {
    if (!localStorage.getItem("ca"))
      this.bottomSheet.open(CookieRequestDialogComponent, { hasBackdrop: false });
  }

  auth(): boolean {
    return this.authService.isLogged();
  }

  onActions = () => {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }
    this.logoutTimeout = setTimeout(() => {
      if (this.authService.isLogged()) {
        this.authService.logout();
        this.router.navigate([document.location.pathname]);
      }
    }, 900000);
  }
}
