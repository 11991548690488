<header class="header">
    <nav class="navbar navbar-expand-lg h-pane">
	<div class="h-pane__logo">
	<a href="https://reg.rikc.by/">
		<img src="assets/img/logo__abdurt.svg" alt="">
      </a>
	</div>
      <div class="h-pane__nam">
        <span [innerHtml]="'HOME.SYSREG' | translate"></span>
	</div>
	 <div class="h-pane__translate">
        <button [hidden]="translate.currentLang=='ru'" (click)="set('ru')"><i class="fa fa-flag"></i>рус</button>
        <button [hidden]="translate.currentLang=='by'" (click)="set('by')"><i class="fa fa-flag"></i>бел</button>
      </div>
      <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-align-left"></i>
      </button>
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse h-pane__link" id="navbarSupportedContent">
	 
	<div class="h-pane__ico nav-hidden__md">
        <div class="h-pane__ico-item">
          <a target="_blank" href="https://rikc.by/pretrialtesting/552-punkty-provedeniya-rt.html">
           <div class="h-pane__ico-img"></div>
            <span class="h-pane__ico-text" [innerHtml]="'HOME.LOCATION' | translate"></span>
            </a>
        </div>

        <div class="h-pane__ico-item">
          <a target="_blank" href="https://rikc.by/pretrialtesting/335-repeticionnoe-testirovanie.html#pay__rt">
		   <div class="h-pane__ico-img"></div>          
            <span class="h-pane__ico-text"  [innerHtml]="'HOME.WHEREPAY' | translate"></span>           
          </a>
        </div>
        
        <div class="h-pane__ico-item">
          <a target="_blank" href="https://rikc.by/pretrialtesting/484-pamyatka-uchastniku-rt.html">
		   <div class="h-pane__ico-img"></div>           
              <span class="h-pane__ico-text" [innerHtml]="'HOME.REGISTRATION' | translate"></span>
            </a>
        </div> 
<div class="h-pane__ico-item" style="display: none">
          <a target="_blank" href="https://rikc.by/?do=feedback">
		   <div class="h-pane__ico-img"></div>
              <span class="h-pane__ico-text"  [innerHtml]="'HOME.EMAIL' | translate"></span>
            </a>
        </div> 		
      </div>
	  <div class="h-pane__support">
       <span [innerHtml]="'HOME.SUPPORT' | translate"></span>
       <div class="dropdown" placement="bottom-right" ngbDropdown>
         <div *ngIf="pathUser" class="h-pane__support-number"><a href="tel:80173110084"><i class="fa fa-phone" aria-hidden="true"></i>8 <span>(017)</span> 311-00-84</a><div class="nav-link dropdown-toggle" id="navbarDropdown" ngbDropdownToggle></div></div>
         <div *ngIf="pathAdmin" class="h-pane__support-number"><a href="tel:80173110004"><i class="fa fa-phone" aria-hidden="true"></i>8 <span>(017)</span> 311-00-04</a><div class="nav-link dropdown-toggle" id="navbarDropdown" ngbDropdownToggle></div></div>
         <div class="dropdown-menu" aria-labelledby="navbarDropdown" ngbDropdownMenu>
           <ul>
             <li><i class="fa fa-clock-o" aria-hidden="true"></i><span class="name-logout" [innerHtml]="'LOGOUT.SUPPORT.WORKTIME' | translate"></span></li>
             <li><i class="fa fa-clock-o" aria-hidden="true"></i><span class="name-logout" [innerHtml]="'LOGOUT.SUPPORT.LUNCHTIME' | translate"></span></li>
             <!-- <li><i class="fa fa-mobile" aria-hidden="true"></i><a href="tel:+375291118168">8 <span>(029)</span> 111-81-68</a></li> -->
             <li>
               <i class="fa fa-phone" aria-hidden="true"></i>
               <a *ngIf="pathUser" href="tel:80173110084">8 <span>(017)</span> 311-00-84</a>
               <a *ngIf="pathAdmin" href="tel:80173110004">8 <span>(017)</span> 311-00-04</a>
             </li>
             <li><a href="assets/files/help.pdf" target="_blank"><i class="fa fa-book"></i>{{'HOME.HELP' | translate}}</a></li>
           </ul>
         </div>
       </div>     
      </div>
      </div>
    </nav>
</header>
