/* src/app/cookie-request-dialog/cookie-request-dialog.component.css */
.cookie__item--buttons {
  display: flex;
}
.cookie__item--buttons .btn {
  margin-right: .5rem;
}
@media only screen and (max-width:820px) {
  .cookie__item .d-flex {
    flex-direction: column;
    align-items: center;
  }
}
/*# sourceMappingURL=cookie-request-dialog.component.css.map */
