/* src/app/logout/logout.component.css */
.logout__items--cart img {
  width: 30px;
}
.logout__items--cart a {
  position: relative;
}
.items__cart--counter {
  width: 18px;
  background-color: #fb9130;
  font-weight: 500;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  border-radius: 16px;
  position: absolute;
  top: calc(50% - 17px);
  left: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.items__notify--counter {
  width: 18px;
  background-color: #6c757d;
  font-weight: 500;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  border-radius: 16px;
  position: absolute;
  top: calc(50% - 17px);
  right: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout__items--notify img {
  width: 22px;
}
.logout__items--notify {
  position: relative;
}
.logout__items {
  display: flex;
  align-items: center;
}
.logout__items--cart {
  margin-right: .7rem;
}
.h-pane__support-number {
  display: flex;
  align-items: center;
}
.dropdown-menu--user {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.dropdown-menu {
  border: 1px solid #e7f0f7;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 29%);
}
.dropdown-menu:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #e7f0f7;
  position: absolute;
  top: -6px;
  right: 10px;
}
.dropdown-menu--user > * {
  padding: 0.1rem 0.6rem;
  font-size: .9rem;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.6);
}
.dropdown-menu--user button {
  color: #fff;
  margin-top: 5px;
}
.dropdown-divider {
  margin: 0.5rem 0 0;
}
.dropdown-toggle::after {
  border: none !important;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f107";
  vertical-align: 0;
  position: relative;
}
.logout__items--user {
  cursor: pointer;
}
.dropdown-menu--user .fa {
  padding-right: 0.4rem;
}
.dropdown-menu--user a:hover {
  background-color: #6796ad;
  color: #fff;
  text-decoration: none;
  transition: all .3s ease;
}
.dropdown-menu--user a {
  padding: 0.4rem 0.6rem;
}
.logout__items--notify .dropdown-toggle::after {
  content: none;
}
.logout__items--notify {
  cursor: pointer;
}
.nav-link {
  padding: 0.5rem;
}
.dropdown-menu--notify {
  min-width: 250px;
}
.menu__notify--header {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  font-size: .9rem;
  color: rgba(0, 0, 0, 0.6);
}
.menu__notify--header img {
  opacity: .7;
  cursor: pointer;
  transition: all ease 250ms;
}
.menu__notify--header img:hover {
  opacity: 1;
}
.menu__notify--body {
  background-image: url(/assets/img/notify-no-more.svg);
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 250px;
  font-size: .9rem;
  color: rgba(0, 0, 0, 0.6);
}
.menu__notify--body li {
  position: relative;
  width: 100%;
  padding: .5rem 1rem;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(240, 247, 253, 0.71);
  font-weight: 500;
  text-align: center;
  margin-bottom: .3rem;
  transition: all ease 250ms;
}
.menu__notify--body li:hover {
  color: inherit;
  background: rgba(240, 247, 253);
}
.menu__notify--footer {
  font-size: .9rem;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  width: 100%;
  background: rgba(240, 247, 253, 0.71);
  font-weight: 400;
  text-align: center;
  padding: .5rem 1rem;
}
.menu__notify--body li::before {
  content: "";
  font-size: 1.2rem;
  width: 8px;
  height: 8px;
  display: block;
  background: #4caf50;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 15px;
}
/*# sourceMappingURL=logout.component.css.map */
