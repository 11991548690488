import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent {
  isNavbarCollapsed = true;
  pathUser: boolean = true;
  pathAdmin: boolean = false;
  constructor(public translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (this.activatedRoute.snapshot.queryParams) {
        var url = this.activatedRoute.snapshot.queryParams.returnUrl;
        if (url) {
          if (url.includes("admin")) {
            this.pathUser = false;
            this.pathAdmin = true;
          }
          else {
            this.pathUser = true;
            this.pathAdmin = false;
          }
        }
      }
    });
  }

  set(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }
}


